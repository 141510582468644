import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

const PageLoader: FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      component="main"
      className={classes.root}
    >
      <CircularProgress />
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    background: theme.palette.background.default,
  },
}));

export default PageLoader;
