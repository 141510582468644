export const FORGOT_PASSWORD_ROUTE = '/auth/forgot-password';
export const SIGN_IN_ROUTE = '/auth/sign-in';
export const SIGN_UP_ROUTE = '/auth/sign-up';
export const CONFIRMATION_ROUTE = 'auth/confirmation';
export const RESEND_CONFIRMATION_ROUTE = '/auth/resend-confirmation';
export const UPDATE_PASSWORD_ROUTE = '/auth/update-password';

export const OVERVIEW_ROUTE = '/overview';
export const TRANSACTIONS_ROUTE = '/overview/transactions';
export const BUSINESS_TRANSACTION_LIST_ROUTE =
  '/overview/business-transactions';
export const BUSINESS_BOOKING_LIST_ROUTE = '/overview/business-bookings';

export const NEW_MEMBER_ROUTE = '/members/new';
export const MEMBER_LIST_ROUTE = '/members';
export const MEMBER_DETAIL_ROUTE = '/members/[id]';
export const NEW_GROUP_MEMBER_ROUTE = '/members/groups/new';
export const GROUP_MEMBER_LIST_ROUTE = '/members/groups';
export const GROUP_MEMBER_DETAIL_ROUTE = '/members/groups/[id]';

export const NEW_STAFF_ROUTE = '/staffs/new';
export const STAFF_LIST_ROUTE = '/staffs';
export const STAFF_PAYROLLS_ROUTE = '/staffs/payrolls';
export const STAFF_DETAIL_ROUTE = '/staffs/[id]';

export const NEW_CLASS_ROUTE = '/classes/new';
export const CLASS_LIST_ROUTE = '/classes';
export const CLASS_DETAIL_ROUTE = '/classes/[id]';
export const CLASS_TIMETABLE_ROUTE = '/classes/timetable';

export const NEW_FACILITY_ROUTE = '/facilities/new';
export const FACILITY_LIST_ROUTE = '/facilities';
export const FACILITY_DETAIL_ROUTE = '/facilities/[id]';

export const NEW_MEMBERSHIP_ROUTE = '/memberships/new';
export const MEMBERSHIP_LIST_ROUTE = '/memberships';
export const MEMBERSHIP_DETAIL_ROUTE = '/memberships/[id]';

export const RESOURCE_TAG_LIST_ROUTE = '/resource-tags';

export const CLASS_BOOKING_LIST_ROUTE = '/bookings';
export const TRAINER_BOOKING_LIST_ROUTE = '/bookings/trainer';
export const FACILITY_BOOKING_LIST_ROUTE = '/bookings/facility';

export const GYM_PROFILE_ROUTE = '/gym-profile';

export const RETAIL_ROUTE = '/retail';
export const RETAIL_PRODUCT_LIST_ROUTE = '/retail/products';
export const RETAIL_ADD_PRODUCT_ROUTE = '/retail/products/new';
export const RETAIL_PRODUCT_DETAIL_ROUTE = '/retail/products/[id]';
export const RETAIL_SHOP_ROUTE = '/retail/shop';

export const MAINTENANCE_ROUTE = '/maintenance';
