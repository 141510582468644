import { NextRouter, useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';

import PageLoader from '~/shared/components/PageLoader';

type Props = {
  url: Parameters<NextRouter['push']>[0];
};

const RedirectedPage: FC<Props> = ({ url }) => {
  const { replace } = useRouter();

  useEffect(() => {
    replace(url);
  }, [replace, url]);

  return <PageLoader />;
};

export default RedirectedPage;
