import { NextPage } from 'next';
import React from 'react';

import { OVERVIEW_ROUTE } from '~/shared/constants/routes';
import RedirectedPage from '~/shared/containers/RedirectedPage';

const Page: NextPage = () => {
  return <RedirectedPage url={OVERVIEW_ROUTE} />;
};

export default Page;
